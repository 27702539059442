<template>
  <section class="hero is-fullheight">
    <full-screen-loader></full-screen-loader>
    <div class="hero-body has-text-centered">
      <div class="container">
        <div class="columns is-centered" style="width:400px">
          <div>
            <div class="" style="padding-bottom: 1.5rem; ">
              <form class="card-content p-0" @submit.prevent="login">
                <img src="../assets/logo.png" alt="logo">
                <br>
                <br>
                <p class="title is-3  responsive-title mb-0" style=" margin-top:4px; font-weight: bold;">Welcome Back!
                </p>
                <p class="title is-4 responsive-title">Let's sign in</p>
                <!-- <img src="../assets/Login.png" style="width: 250px; margin-bottom: 15px;"> -->

                <b-field label="Username" label-position="on-border" :type="loginData.username.class"
                  :message="loginData.username.message">
                  <b-input maxlength="30" icon="account" v-model="loginData.username.value"
                    @input="loginData.username.blur()"></b-input>
                </b-field>

                <b-field label="Password" label-position="on-border" :type="loginData.password.class"
                  :message="loginData.password.message">
                  <b-input :type="passwordVisible ? '' : 'password'" icon="form-textbox-password" maxlength="30"
                    v-model="loginData.password.value" @input="loginData.password.blur()" password-reveal></b-input>
                </b-field>

                <div class="buttons">
                  <b-button style="background-color: #0055A1;" class="is-primary" native-type="submit"
                    :loading="btnLoading" expanded>Login</b-button>
                </div>
              </form>
              <div class="has-text-extra-small">Version <strong>{{ getAppVersionData().version }}</strong> © {{
                getAppVersionData().year }} Ceylon Remit (Pvt) Ltd. All Rights Reserved</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import jwtUtils from "@/helpers/jwtUtils"
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import FullScreenLoader from "@/components/FullScreenLoader";
import { appSettings } from "@/appSettings";
import User from "@/mixins/modules/authMixins";


export default {
  name: 'Login',
  components: { FullScreenLoader },
  data() {
    return {
      btnLoading: false,
      passwordVisible: false,
      loginData: {
        username: new FieldValidator(),
        password: new FieldValidator()
      },
      message: ''
    }
  },

  methods: {

    getAppVersionData: function () {
      return {
        version: appSettings.version,
        year: appSettings.year
      }
    },

    validate: function () {
      let isValid = true

      let usernameErrorMessage = "Username cannot contain spaces or special characters except '_'"
      if (!this.loginData.username.validate(usernameErrorMessage)) isValid = false

      let passwordErrorMessage = "Please enter a valid password"
      if (!this.loginData.password.validate(passwordErrorMessage)) isValid = false

      return isValid
    },

    login: async function (e) {
      //e.preventDefault()
      const self = this

      if (self.validate()) {
        self.btnLoading = true

        const data = {
          username: self.loginData.username.value,
          password: self.loginData.password.value
        }

        await NetworkManager.apiRequest('api/auth', data,
          function (e) {
            
            let params = {
              type: '',
              message: ''
            }

            switch (e.statusCode) {
              case 200:
                jwtUtils.saveToken(e.data.token)
                if (e.data.resetPassword === 1) {
                  self.showFullScreenLoader()
                  self.navigateTo('ResetPassword')
                }
                else {
                  self.showFullScreenLoader()

                  if ((User.hasPermission('auditor-dashboard-view') || User.hasPermission('auditor-report-view')) && !User.hasSuperPermission()) {
                    self.navigateTo('AuditDashboard');
                  } else {
                    self.navigateTo('Dashboard');
                  }
                }
                break
              case 400:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
              case 422:
                params = {
                  type: 'is-warning',
                  message: e.data.message
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
              case 404:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
              case 500:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
            }

            self.btnLoading = false
          }, false)
      }
    }
  },
  mounted() {
    this.stopTokenTimer()
  }
}
</script>

<style scoped>
.hero {
  background-image: url(../assets/Login.png);
  background-size: cover;
}

.has-text-extra-small {
  font-size: 13px;
}
</style>